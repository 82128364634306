<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" sm="8">
          <b-card no-body class="mx-4">
            <b-card-body class="p-4">
              <b-form>
                <h1>등록하기</h1>
                <p class="text-muted">계정 생성을 위한 정보들을 입력해주세요</p>
                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-control" v-model="form.id" placeholder="ID" autocomplete="id"/>
                </b-input-group>

                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>@</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="email" class="form-control" v-model="form.email" :readonly="readonly.email" placeholder="Email" autocomplete="email"/>
                  <b-input-group-append v-if="form.email === form.email_auth && form.email_txid">
                    <b-button variant="secondary" disabled>
                      인증됨
                    </b-button>
                  </b-input-group-append>
                  <template v-else>
                    <b-input-group-append>
                      <b-button class="" variant="success" @click="sendAuthEmail" :disabled="busy.sendEmail || busy.waitEmail">
                        <span v-if="form.email_sended && form.email === form.email_sended">
                          재발송
                        </span>
                        <span v-else>
                          인증메일발송
                        </span>
                        <b-spinner class="mr-1" small v-if="busy.sendEmail"></b-spinner>
                      </b-button>
                    </b-input-group-append>
                  </template>
                </b-input-group>
                <template v-if="form.email_sended && form.email === form.email_sended && !form.email_txid">
                  <b-input-group>
                    <b-input v-model="form.emailAuthCode" :placeholder="emailLimitSecond ?
                     `${Math.floor(emailLimitSecond / 60)}:${(emailLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
                      `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
                    <b-input-group-append>
                      <b-button class="" variant="primary" @click="checkAuthEmail" :disabled="busy.checkEmail || !emailLimitSecond">
                        인증
                        <b-spinner class="mr-1" small v-if="busy.checkEmail"></b-spinner>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </template>

                <div class="mb-3"></div>

                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icons cui-phone"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="tel" class="form-control" v-model="form.mobile" :readonly="readonly.mobile" placeholder="휴대폰 번호" autocomplete="mobile"/>
                  <b-input-group-append v-if="form.mobile === form.mobile_auth && form.mobile_txid">
                    <b-button variant="secondary" disabled>
                      인증됨
                    </b-button>
                  </b-input-group-append>
                  <template v-else>
                    <b-input-group-append>
                      <b-button class="" variant="success" @click="sendAuthSMS" :disabled="busy.sendSMS || busy.waitSMS || form.is_abroad">
                        <span v-if="form.mobile_sended && form.mobile === form.mobile_sended">
                          재발송
                        </span>
                        <span v-else>
                          인증문자발송
                        </span>
                        <b-spinner class="mr-1" small v-if="busy.sendSMS"></b-spinner>
                      </b-button>
                    </b-input-group-append>
                  </template>
                </b-input-group>
                <template v-if="form.mobile_sended && form.mobile === form.mobile_sended && !form.mobile_txid">
                  <b-input-group>
                    <b-input v-model="form.smsAuthCode" :placeholder="smsLimitSecond ?
                     `${Math.floor(smsLimitSecond / 60)}:${(smsLimitSecond % 60).toString().padStart(2, '0')} 이내로 입력해주세요` :
                      `인증시간이 초과되었습니다`" autocomplete="off"></b-input>
                    <b-input-group-append>
                      <b-button class="" variant="primary" @click="checkAuthSMS" :disabled="busy.checkSMS || !smsLimitSecond">
                        인증
                        <b-spinner class="mr-1" small v-if="busy.checkSMS"></b-spinner>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </template>

                <div class="mb-3"></div>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-pencil"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" class="form-control" v-model="form.name" placeholder="이름" autocomplete="name"/>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-people"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select v-model="form.teamId" :options="[
                      {text: '(팀을 선택해주세요)', value: '', disabled: true},
                      ...TEAMS.filter(e => !e.old).map(e => ({text: '　　　'.slice(0, e.padding) + e.text, value: e.value})),
                    ]"></b-form-select>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-people"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select v-model="form.workType" :options="[
                      {text:'(근무형태를 선택해주세요)', value:'', disabled: true},
                      ...options.workType
                    ]"></b-form-select>
                </b-input-group>

                <b-input-group class="mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="password" class="form-control" v-model="form.pw" placeholder="비밀번호" autocomplete="new-password"/>
                </b-input-group>

                <b-input-group class="mb-4">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="password" class="form-control" v-model="form.pwAgain" placeholder="비밀번호 확인" autocomplete="new-password"/>
                </b-input-group>

                <b-button variant="success" @click="join(['hub'])" :disabled="busy.join" block>
                  HUB 계정 생성하기
                  <b-spinner class="mr-1" small v-if="busy.join"></b-spinner>
                </b-button>
                <b-button variant="primary" @click="join(['hub', 'partner'])" :disabled="busy.join" block>
                  HUB & 파트너센터 계정 생성하기
                  <b-spinner class="mr-1" small v-if="busy.join"></b-spinner>
                </b-button>
                <b-button variant="outline-primary" @click="join(['partner'])" :disabled="busy.join" block>
                  기존 HUB 사용자의 파트너센터 계정만 생성하기
                  <b-spinner class="mr-1" small v-if="busy.join"></b-spinner>
                </b-button>
                <b-button variant="light" @click="$router.go(-1)" block>이전으로</b-button>
                <b-button v-if="IS_LOCAL" variant="light" @click="setTestData" block>[Local] TestData</b-button>
              </b-form>
            </b-card-body>
            <!--<b-card-footer class="p-4">
              <b-row>
                <b-col cols="6">
                  <b-button block class="btn btn-facebook"><span>facebook</span></b-button>
                </b-col>
                <b-col cols="6">
                  <b-button block class="btn btn-twitter" type="button"><span>twitter</span></b-button>
                </b-col>
              </b-row>
            </b-card-footer>-->
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  title: '가입하기',
  data() {
    return {
      TEAMS: [], // this.$C.TEAMS
      teamMap: {}, // this.$utils.arr2map(this.$C.TEAMS, 'value'),
      teams: [], // this.$C.TEAMS.filter(e => !e.disabled),
      form: {
        id: '',
        email: '',
        email_sended: '',
        emailAuthCode: '',
        email_auth: '',
        email_txid: '',
        mobile: '',
        mobile_sended: '',
        smsAuthCode: '',
        mobile_auth: '',
        mobile_txid: '',
        name: '',
        teamId: '',
        workType: '',
        pw: '',
        pwAgain: '',
        is_abroad: false,
        is_google: false,
      },
      emailLimitSecond: 180,
      smsLimitSecond: 180,
      busy: {
        join: false,
        sendEmail: false,
        waitEmail: false,
        checkEmail: false,
        sendSMS: false,
        waitSMS: false,
        checkSMS: false,
      },
      handle: {
        waitEmail: null,
        expireEmail: null,
        waitSMS: null,
        expireSMS: null,
      },
      readonly: {
        email: false,
        mobile: false,
      },
      options: {
        workType: [
          {text: '발라니어', value: 'balaaneer'},
          {text: '협력사', value: 'partner'},
          {text: '파견직', value: 'dispatch'},
          {text: '계약직/인턴', value: 'contract'},
          {text: '기타', value: 'etc'},
        ],
      }
    };
  },
  created() {
    this.makeTeamTree();
    this.checkCountry();

    if (this.$route.query.id) {
      this.form.is_google = true;
      this.form.id = this.$route.query.id;
      this.form.email = this.$route.query.email;
      this.form.name = this.$route.query.name;
      this.form.email_auth = this.form.email;
      this.form.email_txid = true;
      this.readonly.email = true;
    }
  },
  methods: {
    async checkCountry() {
      const j = await this.$api.getJson(`/user/checkIP`);
      if (j && j.ok && j.geo) {
        this.form.is_abroad = j.geo.country !== 'KR'
      }
    },
    makeTeamTree() {
      const TEAMS = this.TEAMS = this.$utils.clone(this.$C.TEAMS);
      this.teamMap = this.$utils.arr2map(TEAMS, 'value');
      this.teams = TEAMS.filter(e => !e.disabled);
      TEAMS.forEach(e => {
        if (e.parentId) {
          const parent = this.teamMap[e.parentId];
          (parent.subtree = parent.subtree || []).push(e);
        }
      });
      TEAMS.forEach(e => {
        let me = e;
        let padding = 0;
        while (me.parentId && this.teamMap[me.parentId]) {
          padding++;
          me = this.teamMap[me.parentId];
        }
        e.padding = padding;
      });
    },
    async sendAuthEmail() {
      if (!this.form.email.match(/^[a-zA-Z0-9+\-_.]+@([-a-zA-Z0-9]+\.)+[-a-zA-Z0-9]+$/)) {
        this.$utils.alert('Email 을 형식에 맞게 입력해주세요');
        return;
      }
      const r = await this.$api.getJson(`/user/checkUserExistsEmail?email=${this.form.email}`);
      if (r) {
        clearTimeout(this.handle.waitEmail);
        clearInterval(this.handle.expireEmail);
        this.busy.sendEmail = true;
        const j = await this.$api.postJson('/user/sendAuthEmail', {email: this.form.email});
        this.busy.sendEmail = false;
        if (j) {
          this.busy.waitEmail = true;
          this.emailLimitSecond = 180;
          // 다량발송 방지
          this.handle.waitEmail = setTimeout(() => {
            this.busy.waitEmail = false;
            this.$forceUpdate();
          }, 15000);
          // 입력한도시간 표시
          this.handle.expireEmail = setInterval(() => {
            if (--this.emailLimitSecond <= 0) clearInterval(this.handle.expireEmail);
          }, 1000);
          this.form.email_sended = this.form.email;
          this.$utils.alert('인증메일이 발송되었습니다. 확인 후 입력해주세요');
          this.$forceUpdate();
        }
      }
    },
    async checkAuthEmail() {
      if (!this.form.emailAuthCode) return alert('인증번호를 입력해주세요');
      const email = this.form.email;
      const j = await this.$api.postJson('/user/checkAuthEmail', {email, authCode: this.form.emailAuthCode});
      if (j) {
        this.form.email_auth = email;
        this.form.email_txid = j.txid;
        this.readonly.email = true;
        clearInterval(this.handle.expireEmail);
        this.$utils.alert('Email 이 인증되었습니다');
      }
    },
    async sendAuthSMS() {
      if (!this.form.mobile || this.form.mobile.match(/\D/)) {
        this.$utils.alert('휴대폰 번호는 숫자로만 입력해주세요');
        return;
      }
      if (!this.form.mobile.match(/^(820|82|0)[157]\d[2-9]\d{6,8}/)) {
        this.$utils.alert('휴대폰 번호는 문자를 받을 수 있는 번호로 입력해주세요');
        return;
      }
      clearTimeout(this.handle.waitSMS);
      clearInterval(this.handle.expireSMS);
      this.busy.sendSMS = true;
      const j = await this.$api.postJson('/user/sendAuthSMS', {mobile: this.form.mobile});
      this.busy.sendSMS = false;
      if (j) {
        this.busy.waitSMS = true;
        this.smsLimitSecond = 180;
        // 다량발송 방지
        this.handle.waitSMS = setTimeout(() => {
          this.busy.waitSMS = false;
          this.$forceUpdate();
        }, 15000);
        // 입력한도시간 표시
        this.handle.expireSMS = setInterval(() => {
          if (--this.smsLimitSecond <= 0) clearInterval(this.handle.expireSMS);
        }, 1000);
        this.form.mobile_sended = this.form.mobile;
        this.$utils.alert('인증문자가 발송되었습니다. 확인 후 입력해주세요');
        this.$forceUpdate();
      }
    },
    async checkAuthSMS() {
      if (!this.form.smsAuthCode) return alert('인증번호를 입력해주세요');
      const mobile = this.form.mobile;
      const j = await this.$api.postJson('/user/checkAuthSMS', {mobile, authCode: this.form.smsAuthCode});
      if (j) {
        this.form.mobile_auth = mobile;
        this.form.mobile_txid = j.txid;
        this.readonly.mobile = true;
        clearInterval(this.handle.expireSMS);
        this.$utils.alert('휴대폰 번호가 인증되었습니다');
      }
    },
    /**
     * 새롭게 나온 kisa 비밀번호 선택 및 이용 안내서(2019.06) 에 따른 체크
     * https://skyksit.tistory.com/entry/%EB%B9%84%EB%B0%80%EB%B2%88%ED%98%B8-%EC%B2%B4%EA%B3%84-%EB%B3%80%EA%B2%BD-%EB%90%A8-11%EB%85%84-%EB%A7%8C%EC%97%90
     * 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상
     * 한 가지 종류로 10자 이상
     * 6개월마다 변경 필요없음
     *
     * @param {string} pw
     * @return {boolean}
     */
    checkPW(pw) {
      // 문자그룹별 카운트
      const lower = pw.match(/[a-z]/);
      const upper = pw.match(/[A-Z]/);
      const num = pw.match(/[0-9]/);
      const etc = pw.match(/[^0-9a-zA-Z]/);
      const count = [lower, upper, num, etc].filter(e => e).length;
      return count >= 2 && pw.length >= 8 || pw.length >= 10;
    },
    async join(type) {
      if (this.busy.join) return;

      if (this.form.id.length < 4) return this.$utils.alert('ID를 4자 이상으로 입력해주세요');
      if (!this.form.email.match(/^[a-zA-Z0-9+\-_.]+@([-a-zA-Z0-9]+\.)+[-a-zA-Z0-9]+$/)) {
        return this.$utils.alert('이메일을 형식에 맞게 입력해주세요');
      }
      if (!this.form.is_abroad && (!this.form.mobile || this.form.mobile.match(/\D/))) {
        this.$utils.alert('휴대폰 번호는 숫자로만 입력해주세요');
        return;
      }
      if (!this.form.is_abroad && !this.form.mobile.match(/^(820|82|0)[157]\d[2-9]\d{6,8}/)) {
        this.$utils.alert('휴대폰 번호는 문자를 받을 수 있는 번호로 입력해주세요');
        return;
      }
      if (this.form.name.length < 2) return this.$utils.alert('이름을 2자 이상으로 입력해주세요');

      if (!this.checkPW(this.form.pw)) {
        return this.$utils.alert('새 비밀번호는 알파벳 대문자, 소문자, 특수문자, 숫자 중 두가지 종류 이상 8자리 이상의 길이 혹은 10자리 이상의 길이로 입력해주세요');
      }
      if (this.form.pw.length < 8) return this.$utils.alert('비밀번호는 8자 이상으로 입력해주세요');
      if (this.form.pw !== this.form.pwAgain) return this.$utils.alert(`비밀번호는 비밀번호 확인과 동일해야 합니다`);
      if (!this.form.teamId) return this.$utils.alert('팀을 선택해주세요');
      if (!this.form.workType) return this.$utils.alert('근무형태를 선택해주세요');

      if (!this.form.email_txid) return this.$utils.alert('Email 인증을 진행해주세요');
      if (!this.form.is_abroad && !this.form.mobile_txid) return this.$utils.alert('휴대폰 번호 인증을 진행해주세요');

      const {id, email, email_txid, mobile, mobile_txid, name, teamId, workType, pw, pwAgain, is_abroad, is_google} = this.form;
      let j;
      if (type.includes('hub')) {
        this.busy.join = true;
        j = await this.$api.postJson('/user/join', {id, email, email_txid, mobile, mobile_txid, name, teamId, workType, is_abroad, is_google, pw: this.$utils.sha256(pw), pwAgain: this.$utils.sha256(pwAgain)});
        this.busy.join = false;
      }
      if (type.includes('partner')) {
        if (!j || confirm(`허브의 가입정보를 이용해 파트너센터 가입도 진행하시겠습니까?`)) {
          this.busy.join = true;
          j = await this.$api.postJson('/user/joinPartner', {id, email, mobile, name, pw: this.$utils.sha256(pw), pwAgain: this.$utils.sha256(pwAgain)});
          this.busy.join = false;
        }
      }
      if (j) {
        await this.$utils.alert(`계정이 생성되었습니다. 관리자의 승인을 기다려주세요.`, {noCloseOnEsc: true});
        this.$router.replace('/');
      }
    },
    setTestData() {
      this.form.id = 'tester';
      this.form.email = 'test@test.com';
      this.form.mobile = '01020000000';
      this.form.name = 'Tester';
      this.form.pw = 'eB8qOVqxzK';
      this.form.pwAgain = 'eB8qOVqxzK';
      this.form.teamId = 'eB8qOVqxzK'; // 발란
      this.form.workType = 'balaaneer';
      this.form.email_txid = 'qqqqqqqq';
      this.form.mobile_txid = 'qqqqqqqq';
    }
  }
}
</script>
